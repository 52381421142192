import { ref, Ref } from 'vue';
import { timeZone, account } from '@/data';
import { FormData } from '@/components/view/installer/set-user-dialog';
import { isCNServer } from '@/util/location';
import { ListHeaderItem, ListActionItem } from '@/components/common/list';
import { insIsDisChange } from '@/data/src/account';
import { useStore } from 'vuex';

const isShowUserDialog = ref(false);
const userDialogOperaType: Ref<OperateType> = ref('add');

function isValidFormData(formData: FormData | false): formData is FormData {
    return formData !== false;
}
function getUserFormData(value: FormData | false = false) {
    let formData: FormData = {
        ID: '',
        SipGroup: '',
        Name: '',
        RoomNumber: '',
        Email: '',
        PhoneCode: isCNServer() ? '86' : '',
        MobileNumber: '',
        Address: '',
        EnableIpDirect: 1,
        TimeZone: timeZone.getManageDefaultTimeZone(),
        Language: Lang,
        Phone: '',
        Phone2: '',
        Phone3: '',
        PhoneStatus: '1',
        isDisMonitor: '0',
        isSingleMonitor: '0',
        MAC: '',
        Location: '',
        Relay: '',
        EnableSmartHome: '0',
        PremiumPlan: '0',
        IsPremiumPlanStatus: '0',
        IsHasThirdPartyCameras: 0
    };
    if (isValidFormData(value)) {
        formData = { ...value };
        formData.PhoneCode = isCNServer() ? '86' : value.PhoneCode;
        formData.EnableIpDirect = Number(formData.EnableIpDirect);
        formData.EnableSmartHome = formData.EnableSmartHome || '0';
    }

    return formData;
}
const formData = ref(getUserFormData());
// 获取super配置dis能否绑定特殊室内机状态
const getDisMonitor = () => {
    account.getSingleMonitor((res: {
        IsVillaMonitor: string;
    }) => {
        formData.value.isDisMonitor = res.IsVillaMonitor;
    });
};
function addUser() {
    isShowUserDialog.value = true;
    formData.value = getUserFormData();
    getDisMonitor();
    userDialogOperaType.value = 'add';
}

function edit(value: FormData) {
    isShowUserDialog.value = true;
    formData.value = getUserFormData(value);
    getDisMonitor();
    userDialogOperaType.value = 'edit';
}

const initData = () => {
    const store = useStore();
    const action: Array<ListActionItem> = [{
        type: 'smartHome',
        event: 'goToSmartHome',
        class: 'el-icon-my-zhinengjiaju_icon color-main',
        title: WordList.HomeAutoMation,
        showCondition(val: { EnableSmartHome: string }) {
            return store.state.enableSmartHome && val.EnableSmartHome === '1' && insIsDisChange.value !== '1';
        }
    }, {
        type: 'device',
        event: 'goToDevice',
        class: 'el-icon-my-devices color-main',
        title: WordList.TabelUpdateBoxDevice
    }];
    const headers: Array<ListHeaderItem> = [{
        name: 'Name',
        label: WordList.TabelPersonUserInHtmlName
    }, {
        name: 'SipGroup',
        label: WordList.MulListUpdateListTanleAccount,
        type: 'customize'
    }, {
        name: 'RoomNumber',
        label: WordList.TabelPersonKeyInHtmlRoom
    }, {
        name: 'Email',
        label: WordList.TabelPropertyManageEmail
    }, {
        name: 'MobileNumber',
        label: WordList.ProperAllTextMobileNumber
    }, {
        name: 'Address',
        label: WordList.TabelCommunitiesAddress
    }, {
        name: 'SubCount',
        label: WordList.NavInHtmlMenuUser2,
        type: 'customize'
    }, {
        name: 'Active',
        label: WordList.RDeviceListTanleActive
        // type: 'customize'
    },
    // {
    //     name: 'Landline',
    //     label: WordList.ProperAllTextLandlineStatus
    // },
    {
        name: 'PremiumPlan',
        label: WordList.PremiumPlan,
        type: 'customize'
    }, {
        name: 'CreateTime',
        label: WordList.TabelMessageBoxCreateTime
    }, {
        name: 'PhoneExpireTime',
        label: `${WordList.TabelExpretimeBoxSpecificTime}(${WordList.Premium})`
    }];
    const searchKeyList = [{
        label: WordList.TabelPersonUserInHtmlName,
        name: 'Name'
    }, {
        label: WordList.TabelPropertyManageEmail,
        name: 'Email'
    }, {
        label: WordList.ProperAllTextMobileNumber,
        name: 'MobileNumber'
    }, {
        label: WordList.MulListUpdateListTanleAccount,
        name: 'Sip'
    }];
    return {
        action,
        headers,
        searchKeyList
    };
};

export {
    userDialogOperaType,
    isShowUserDialog,
    getUserFormData,
    formData,
    addUser,
    edit,
    initData
};
