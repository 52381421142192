

import { defineComponent, Ref, ref } from 'vue';
import { useStore } from 'vuex';
import {
    listPagination, RequestData, getSearch
} from '@/components/common/list';
import setUserDialog from '@/components/view/installer/set-user-dialog';
import addButton from '@/components/common/add-button/index.vue';
import router from '@/router';
import { installerBaseRouter, installerSubRouter } from '@/router/data';
import userForSingleApi from '@/api/installer/user';
import Notice from '@/util/notice.base';
import { loginApi } from '@/api';
import { premiumPlanFieldMap, activeFieldMap } from '@/constant/field-map';
import {
    userDialogOperaType, isShowUserDialog, addUser, edit, formData, initData
} from './util';

export default defineComponent({
    components: {
        listPagination,
        addButton,
        setUserDialog
    },
    setup() {
        const store = useStore();
        const {
            action,
            headers,
            searchKeyList
        } = initData();
        const updateToList = ref(0);
        const saveParamInPath = ref(false);
        const searchList = () => {
            updateToList.value += 1;
            saveParamInPath.value = true;
        };
        const listRequestData: Ref<RequestData> = ref({
            url: 'v3/web/single/user/getMainList',
            param: getSearch({
                searchKey: 'Name',
                searchValue: ''
            })
        });
        const goUserDevice = (deviceData: {
            Account: string | boolean;
            SipGroup: string;
            PremiumPlan: 0|1;
            UUID: string;
            Name: string;
        }) => {
            // router.push(`/${installerBaseRouter}/${installerSubRouter.userdevice}?account=${
            //     deviceData.Account}&familySip=${deviceData.SipGroup}&premiumPlan=${
            //     deviceData.PremiumPlan}&personalAccountUUID=${deviceData.UUID}&owner=${
            //     deviceData.Name}`);
            router.push({
                path: `/${installerBaseRouter}/${installerSubRouter.userdevice}`,
                query: {
                    account: deviceData.Account as any,
                    familySip: deviceData.SipGroup,
                    premiumPlan: deviceData.PremiumPlan,
                    personalAccountUUID: deviceData.UUID,
                    owner: deviceData.Name
                }
            });
        };

        const goSubUser = (ID: string | false = false) => {
            router.push(`/${installerBaseRouter}/${installerSubRouter.familymember}${ID === false ? '' : `?id=${ID}`}`);
        };

        const goToSmartHome = (item: { ID: string }) => {
            userForSingleApi.getSmartHomeUri(
                { ID: item.ID },
                (res: { Url: string }) => {
                    Notice.messageBox(
                        'confirm',
                        WordList.WantToHomeAutomation,
                        '',
                        'warning'
                    )(() => {
                        let unityUrl = '';
                        if (store.state.enableSmartHome) {
                            unityUrl = `${process.env.VUE_APP_MIDDLE_URL}?joinType=login&akuvoxUrl=/${
                                installerBaseRouter}/${installerSubRouter.home}`;
                        }
                        window.open(store.state.enableSmartHome ? `${
                            res.Url}&unity_url=${encodeURIComponent(unityUrl) as string}` : res.Url);
                    });
                }
            );
        };

        return {
            action,
            headers,
            updateToList,
            listRequestData,
            searchKeyList,
            isShowUserDialog,
            userDialogOperaType,
            addUser,
            edit,
            formData,
            goUserDevice,
            searchList,
            saveParamInPath,
            goSubUser,
            goToSmartHome,
            premiumPlanFieldMap
        };
    }
});
